<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.dashboard')" class="route-title" />

      <Tippy tag="a" class="tooltip" :content="$t('app.refresh_content')">
        <RefreshCcwIcon
          class="text-theme-1 cursor-pointer"
          @click="refreshData"
        />
      </Tippy>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-3 gap-8">
      <div class="col-span-3 xl:col-span-2">
        <Summary :summary="data?.summary" class="mb-8" />

        <div class="grid grid-cols-3 gap-8 mb-8">
          <VStatisticsCard
            :title="$t('app.competency_statuses', 2)"
            :statistics="data?.competencies?.statuses"
          />

          <ExpiringCompetencies
            class="col-span-2"
            :competencies="data?.competencies?.expiring"
          />
        </div>

        <RegistrationsToComplete
          :data="data?.registrations_to_complete"
          class="mb-8"
        />
      </div>

      <div class="col-span-3 xl:col-span-1">
        <LearningScore :learning-scores="learning_scores" class="mb-8" />

        <ScheduledObservations
          :events="data?.observations_this_month"
          class="mb-8"
        />

        <Pending
          :data="data?.pending_approvals"
          class="mb-8"
          :title="$t('app.pending_approvals', 2)"
        />

        <Pending
          :data="data?.pending_requests"
          :title="$t('app.pending_requests', 2)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VStatisticsCard from "@/components/VStatisticsCard";
import ExpiringCompetencies from "./ExpiringCompetencies";
import ScheduledObservations from "./ScheduledObservations";
import RegistrationsToComplete from "./RegistrationsToComplete";
import Pending from "./Pending";
import Summary from "./Summary";
import LearningScore from "./LearningScore";

export default {
  components: {
    VTitle,
    LearningScore,
    VStatisticsCard,
    ExpiringCompetencies,
    ScheduledObservations,
    RegistrationsToComplete,
    Pending,
    Summary
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();

    // Data
    const isLoading = ref(false);
    const data = ref({});

    // Computed
    const documentTitle = computed(
      () => `${t("app.dashboard")} - ${t("app.learning")}`
    );
    const learning_scores = computed(() => data.value?.learning_scores);

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "learning.dashboard",
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};

      isLoading.value = false;
    };

    const refreshData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "learning.dashboard.refresh",
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};

      isLoading.value = false;
    };

    // Lifecyle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      learning_scores,
      isLoading,
      data,
      refreshData
    };
  }
};
</script>
