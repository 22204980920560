<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <VTitle :title="title" class="overview-title" />

      <VDropdown
        dropdown-menu-class="w-48"
        :selected="currentTab"
        :options="options"
        @click="updateTab"
      >
        <template #option="{ option, index }">
          <div class="flex justify-between items-center">
            <div
              class="capitalize truncate"
              :class="[
                { 'mt-2': index !== 0 },
                { 'text-gray-400 cursor-not-allowed': option.disabled },
                { 'cursor-pointer': !option.disabled }
              ]"
            >
              {{ option.text }}
            </div>

            <div :class="{ 'text-gray-400': option.disabled }">
              {{ option.count }}
            </div>
          </div>
        </template>
      </VDropdown>
    </div>

    <VList
      :rows="displayedRows"
      :headers="headers"
      hide-header
      hide-headers
      @click:row="onClickRedirect"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :color="item.color" :name="item.name" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        <div>
          <div class="font-medium whitespace-nowrap">
            {{ item.name }}
          </div>

          <div v-if="currentTab !== TABS.ATTESTATIONS" class="version mt-0.5">
            {{ $t("app.versions") }} {{ item.version }}
          </div>
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
import useOptions from "@/composables/useOptions";
import useTabs from "@/composables/useTabs";
// Components
import VImage from "@/components/VImage";
import VTitle from "@/components/VTitle";
import VDropdown from "@/components/VDropdown";
import VList from "@/components/tables/VList";

export default {
  components: {
    VDropdown,
    VTitle,
    VList,
    VImage
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const TABS = {
      ATTESTATIONS: "attestations",
      COLLECTION_REGISTRATIONS: "collection_registrations",
      EVALUATIONS: "evaluations",
      REGISTRATIONS: "registrations"
    };
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        value: "name"
      }
    ];

    // Composables
    const { currentTab, updateTab } = useTabs(TABS.ATTESTATIONS);
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const options = computed(() => {
      return Object.values(TABS).map(tab => ({
        text: t(`app.${tab}`, 2),
        value: tab,
        disabled: !props.data[tab]?.length,
        count: props.data?.length ?? 0
      }));
    });
    const attestations = computed(() => {
      return props.data?.attestations?.map(registration => {
        return formatData(registration, "attestation");
      });
    });
    const evaluations = computed(() => {
      return props.data?.evaluations?.map(registration => {
        return formatData(registration, "evaluation");
      });
    });
    const registrations = computed(() => {
      return props.data?.registrations?.map(registration => {
        return formatData(registration, "training");
      });
    });
    const collection_registrations = computed(() => {
      return props.data?.collection_registrations?.map(registration => {
        return formatData(registration, "collection");
      });
    });
    const displayedRows = computed(() => {
      const rows = {
        [TABS.ATTESTATIONS]: attestations.value,
        [TABS.EVALUATIONS]: evaluations.value,
        [TABS.REGISTRATIONS]: registrations.value,
        [TABS.COLLECTION_REGISTRATIONS]: collection_registrations.value
      };

      return rows[currentTab.value] ?? [];
    });

    // Methods
    const formatData = (registration, type) => ({
      ...registration,
      type,
      clickable: type !== "collection",
      rowClass: type !== "collection" ? "cursor-pointer" : "",
      name: getText(registration[type]?.texts, "name"),
      color: registration[type]?.colour,
      version: registration[type]?.version,
      displayed_status: t(`app.${registration.status}`)
    });

    const onClickRedirect = item => {
      if (!item?.clickable) {
        return;
      }

      const names = {
        training: "learning-registrations-details",
        attestation: "learning-attestations-details",
        evaluation:
          item?.evaluation?.type === ALL_OPTIONS.QUIZ.value
            ? "learning-quizzes-details"
            : "learning-observations-details"
      };
      const name = names[item?.type];

      router.push({
        name,
        params: { id: item?.id }
      });
    };

    // Lifecycle Hooks
    onMounted(() => {
      const a = Object.keys(props.data).find(x => props.data[x].length > 0);

      if (!a) {
        return;
      }

      updateTab(a);
    });

    return {
      options,
      TABS,
      headers,
      displayedRows,
      onClickRedirect,
      // useTabs
      currentTab,
      updateTab,
      // useColor
      getStatusColor
    };
  }
};
</script>
